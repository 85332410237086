<template>
  <div class="styleguide">
    <b-container>
      <b-row>
        <b-col class="text-center"><h2>Languages</h2></b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="(language, index) in languages"
          :key="language.code"
          :index="index"
        >
          <button
            :class="
              language == 'zhHT'
                ? `language${index} h3-noto`
                : `language${index} 'h3-gt-america`
            "
            @click="selectLanguage(language.code)"
          >
            <span class="language-name">{{ language.name != "English" ? language.name + " - " : "" }}
              {{
                $store.getters["translate"]("translations", language.code)
                  .TL_LANGUAGE
              }}</span>
          </button>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="lang text-center">
          <p>Language Code: {{ currentLanguage }}</p>
          <p>
            Activation CTA:
            {{ copy }}
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { resolve as env } from '@/common/env'
export default {
  name: 'Languages',
  components: {},
  data () {
    return {
      isMounted: false,
      lastTimeline: null,
      animating: false,
      languages: [
        { name: 'English', code: 'enUS' },
        { name: 'Portugese', code: 'ptPT' },
        { name: 'Chinese', code: 'zhHT' }
      ]
    }
  },
  computed: {
    currentLanguage () {
      return this.$store.state.language.current
    },
    copy () {
      return this.$store.getters['translate'](
        env.VUE_APP_TRANSLATIONS,
        this.$store.state.language.current
      ).TL_BUILT_FOR_SPEED
    }
  },
  methods: {
    selectLanguage (language) {
      this.$store.dispatch('language/SET_LANGUAGE', language)
    }
  }
}
</script>

<style lang="scss" scoped>
.styleguide {
  color: white;
}
</style>
